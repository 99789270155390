import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DiagnosisService } from '../../services/diagnosis.service';
import { Image } from '../../models/diagnosis';
import { forkJoin, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import {
  ConfirmDialogComponent,
  ConfirmDialogConfig,
} from '../../shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-validation-list',
  templateUrl: './validation-list.component.html',
  styleUrls: ['./validation-list.component.css']
})
export class ValidationListComponent implements OnInit, OnDestroy {
  images: ImageWithValidation[] = [];
  unvalidated_count = 0;
  loading = false;

  ncols = 3;

  private bpSub: Subscription;

  constructor(
    private dxService: DiagnosisService,
    private confirmDialog: MatDialog,
    private bpObs: BreakpointObserver,
  ) { }

  ngOnInit() {
    this.refreshList();
    console.log([
      XSmall, Small, Medium,
      Large, XLarge,
    ]);
    this.bpSub = this.bpObs.observe([
      XSmall, Small, Medium,
      Large, XLarge,
    ]).subscribe(v => {
      if (v.breakpoints[XSmall]) {
        this.ncols = 1;
      } else if (v.breakpoints[Small]) {
        this.ncols = 2;
      } else if (v.breakpoints[Medium]) {
        this.ncols = 3;
      } else if (v.breakpoints[Large]) {
        this.ncols = 4;
      } else if (v.breakpoints[XLarge]) {
        this.ncols = 5;
      }
    });
  }

  ngOnDestroy() {
    this.bpSub.unsubscribe();
  }

  refreshList() {
    this.loading = true;
    this.dxService.GetImagesToValidate().subscribe(rsp => {
      this.images = rsp.images.map(img => {
        const im: ImageWithValidation = img;
        im.valid = true;
        return im;
      });
      this.unvalidated_count = rsp.unvalidated_count;
      this.loading = false;
    });
  }

  onSubmit() {
    const cfg: ConfirmDialogConfig = {
      header: 'Submit this batch for validation?',
      body: `Are you sure you want to submit this batch for validation? Any image with a red border
        will be marked as invalid, and all the others will be marked as valid.`,
      ok_string: 'Yes',
      cancel_string: 'No',
    };
    const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, { data: cfg });
    dialogRef.afterClosed().subscribe(ok => {
      if (!ok) { return; }
      this.submit();
    });
  }

  submit() {
    // TODO(austin): change to a single API call
    this.loading = true;
    let tasks = [];
    this.images.forEach(img => {
      let obs = this.dxService.AddImageValidation({id: img.id, valid: img.valid});
      tasks.push(obs);
    });
    forkJoin(...tasks).subscribe(v => {
      console.log('submit complete', v);
      this.loading = false;
      this.refreshList();
    });
  }

  toggleValid(i: number) {
    this.images[i].valid = !this.images[i].valid;
  }

  imgURL(img: Image): string {
    if (img.thumbnail_url) {
      return img.thumbnail_url;
    }
    if (img.url) {
      return img.url;
    }
    return '/assets/baseline-remove_circle-24px.svg'; // TODO(austin): replace with another image
  }
}

interface ImageWithValidation extends Image {
  valid?: boolean;
}

const XSmall = '(max-width: 799.99px)';
const Small = '(min-width: 800px) and (max-width: 1199.99px)';
const Medium = '(min-width: 1200px) and (max-width: 1599.99px)';
const Large = '(min-width: 1600px) and (max-width: 1919.99px)';
const XLarge = '(min-width: 1920px)';
