import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// jwtKey is the key used to store the jwt in localStorage
const jwtKey = 'jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthapiService {
  endpoint = environment.authEndpoint;

  private isLoggedIn = false;
  redirectUrl: string;
  token: string;
  user?: User;
  user$: Subject<User> = new Subject<User>();

  constructor(private http: HttpClient) {
    console.log('constructor');
    const ok = this.checkLoggedIn();
    console.log(ok);
  }

  checkLoggedIn(): boolean {
    if (this.isLoggedIn) { return true; }

    const token = localStorage.getItem(jwtKey);
    if (!token) { return false; }

    let user: User;
    try {
      user = this.parseToken(token);
    } catch (err) {
      if ((<Error>err).message === 'jwt is expired') {
        console.log('expired token');
      } else {
        console.error('error parsing token ', err);
      }
      localStorage.removeItem(jwtKey);
      user = undefined;
      return false;
    }

    this.isLoggedIn = true;
    this.token = token;
    this.user = user;
    this.user$.next(this.user); // TODO(austin): remove?
    return true;
  }

  login(username: string, password: string): Observable<string> {
    return this.http.post(`${this.endpoint}/login`, {
      email: username,
      password: password,
    }).pipe(
      map((v: any) => v.data.token),
      tap(token => {
        try {
          this.user = this.parseToken(token);
          this.isLoggedIn = true;
          this.token = token;
          localStorage.setItem(jwtKey, token);
          this.user$.next(this.user);
        } catch (e) {
          // const er: Error = e;
          console.error('invalid token', e);
        }
      }),
    );
  }

  logout() {
    this.isLoggedIn = false;
    this.token = '';
    this.user = undefined;
    localStorage.removeItem(jwtKey);
    this.user$.next(this.user);
  }

  parseToken(s: string): User {
    const parts = s.split('.');
    if (parts.length !== 3) {
      throw new Error('incorrect number of parts');
    }

    const acc: JWT = JSON.parse(atob(parts[1]));

    if (new Date().getTime() / 1000 > acc.exp) {
      throw new Error('jwt is expired');
    }

    const id = parseInt(acc.id, 10);
    return {
      id: id,
      email: acc.email,
    };
  }
}

interface User {
  id: number;
  email: string;
}

interface JWT {
  id: string; // account id as string
  email: string; // account email
  exp: number; // expiry timestamp
}
