import { OverlayRef } from "@angular/cdk/overlay";

export class FilterMenuRemote {

    constructor(private overlayRef: OverlayRef) { }
  
    close(): void {
      this.overlayRef.dispose();
    }
  }
