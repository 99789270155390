import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig, CdkOverlayOrigin, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { FilterMenuComponent } from '../shared/components/filter-menu/filter-menu.component';
import { FilterMenuRemote } from '../shared/components/filter-menu/filter-menu-remote';

@Injectable({
  providedIn: 'root',
})

export class FilterMenuService {

  overlayRef: OverlayRef;
  constructor(private overlay: Overlay) {}

  open(overlayOrigin: CdkOverlayOrigin) {
    const filterPositionStrategy = this.overlay
      .position()
      .connectedTo(
        overlayOrigin.elementRef,
        { originX: 'start', originY: 'bottom' },
        { overlayX: 'start', overlayY: 'top' }
      );

    const config = new OverlayConfig({
      positionStrategy: filterPositionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      hasBackdrop: true,
      backdropClass: 'trans-backdrop',
    });

    this.overlayRef = this.overlay.create(config);

    const filterMenuPortal = new ComponentPortal(FilterMenuComponent);

    this.overlayRef.attach(filterMenuPortal);

    this.overlayRef.backdropClick().subscribe(_ => filterMenuRemote.close());

    const filterMenuRemote = new FilterMenuRemote(this.overlayRef);

    return filterMenuRemote;

  }

  close() {
    this.overlayRef.dispose();
  }


}


