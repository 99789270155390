<form [formGroup]="loginForm" class="login-form">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  <mat-form-field class="full-width">
    <input matInput type="email" placeholder="email" formControlName="email">
  </mat-form-field>
  <mat-form-field class="full-width">
    <input matInput type="password" placeholder="password" formControlName="password">
  </mat-form-field>
  <button mat-flat-button color="primary" (click)="submit()" [disabled]="isLoading">Login</button>
  <span *ngIf="errorMsg" class="error">{{ errorMsg }}</span>
</form>
