import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthapiService } from '../../services/authapi.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  isLoading = false;
  errorMsg: string | undefined = undefined;

  constructor(private authsvc: AuthapiService, public router: Router) { }

  ngOnInit() {
    this.redirect();
  }

  submit() {
    // console.log('submit', this.loginForm.value);
    this.isLoading = true;
    this.errorMsg = undefined;
    const tokenObs = this.authsvc.login(
      this.loginForm.value.email,
      this.loginForm.value.password
    );
    tokenObs.subscribe(
      () => {
        this.errorMsg = undefined;
        this.redirect();
      },
      (err: HttpErrorResponse) => {
        this.isLoading = false;
        if (err.error instanceof ErrorEvent) {
          console.error('Something went wrong', err.error);
          this.errorMsg = err.error.message;
        } else {
          if (err.status === 401 || err.status === 422) {
            this.errorMsg = 'Invalid email or password';
          } else {
            console.error('Something went wrong', err.error);
            this.errorMsg = 'Something went wrong';
          }
        }
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  redirect() {
    if (!this.authsvc.checkLoggedIn()) { return; }

    const redirect = this.authsvc.redirectUrl ?
      this.router.parseUrl(this.authsvc.redirectUrl) :
      '/sessions';
    this.router.navigateByUrl(redirect);
  }
}
