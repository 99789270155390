import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  SessionWithImages,
  Image,
  MediaType,
  Diagnosis,
  PostDiagnosisReq,
  PatientMetadataDisplay,
  getPatientMetadata,
  ImgDiagnosis,
  isDiagnosisCompleted,
  getEarBadge,
} from '../../models/diagnosis';
import { DiagnosisService } from '../../services/diagnosis.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { SessionDetailsComponent } from '../session-details/session-details.component';
import {
  ConfirmDialogComponent,
  ConfirmDialogConfig,
} from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-session-diagnose-view',
  templateUrl: './session-diagnose-view.component.html',
  styleUrls: ['./session-diagnose-view.component.css']
})
export class SessionDiagnoseViewComponent implements OnInit, OnDestroy {
  @Input() session: SessionWithImages;
  @Input() state: OpenState;
  @Output() completed: EventEmitter<State> = new EventEmitter();

  patientMetadata: PatientMetadataDisplay;

  leftDiagnosis: ImgDiagnosis;
  rightDiagnosis: ImgDiagnosis;

  ncols = 4;

  pluralMap: {[key: string]: string} = {'=1': '# image', 'other': '# images'};

  OpenState = OpenState;

  private bpSub: Subscription;

  constructor(
    private dxService: DiagnosisService,
    public dialog: MatDialog,
    private confirm_dialog: MatDialog,
    private bpObs: BreakpointObserver,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.session.images = this.session.images.sort((a, b): number => {
      if (a.typ === b.typ) {
        return a.id - b.id;
      }
      return a.typ - b.typ;
    });
    const summ: SessionSummary = this.session.images.reduce((p, c, i): SessionSummary => {
      if (c.typ === MediaType.ImageLeft) {
        p.left_count++;
        if (c.has_diagnosis) {
          p.left_diagnosed.push(c);
        }
      } else if (c.typ === MediaType.ImageRight) {
        p.right_count++;
        if (c.has_diagnosis) {
          p.right_diagnosed.push(c);
        }
      }

      return p;
    }, {left_count: 0, right_count: 0, left_diagnosed: [], right_diagnosed: []});

    if (summ.left_count > 0) {
      let expected_id: number | undefined;
      if (summ.left_diagnosed.length === 1) {
        expected_id = summ.left_diagnosed[0].id;
      }
      this.leftDiagnosis = new ImgDiagnosis(true, expected_id);
    } else {
      this.leftDiagnosis = new ImgDiagnosis(false);
    }
    if (summ.right_count > 0) {
      let expected_id: number | undefined;
      if (summ.right_diagnosed.length === 1) {
        expected_id = summ.right_diagnosed[0].id;
      }
      this.rightDiagnosis = new ImgDiagnosis(true, expected_id);
    } else {
      this.rightDiagnosis = new ImgDiagnosis(false);
    }

    this.patientMetadata = getPatientMetadata(this.session.session.metadata);

    this.bpSub = this.bpObs.observe([
      XSmall, Small, Medium,
      Large, XLarge,
    ]).subscribe(v => {
      if (v.breakpoints[XSmall]) {
        this.ncols = 1;
      } else if (v.breakpoints[Small]) {
        this.ncols = 2;
      } else if (v.breakpoints[Medium]) {
        this.ncols = 3;
      } else if (v.breakpoints[Large]) {
        this.ncols = 4;
      } else if (v.breakpoints[XLarge]) {
        this.ncols = 5;
      }
    });
  }

  ngOnDestroy() {
    this.bpSub.unsubscribe();
  }

  setDiagnosis(img: Image, dx: Diagnosis) {
    if (img.typ === MediaType.ImageLeft) {
      this.leftDiagnosis.setDiagnosis(img.id, dx);
    } else if (img.typ === MediaType.ImageRight) {
      this.rightDiagnosis.setDiagnosis(img.id, dx);
    } else {
      throw new Error('media is not an image');
    }
  }

  OnSave() {
    console.debug('onSave');
    if (!this.leftDiagnosis.completed() || !this.rightDiagnosis.completed()) {
      throw new Error('diagnoses are not completed');
    }

    this.dxService.PostDiagnosis(PostDiagnosisReq.createFrom({
      left: this.leftDiagnosis.getDiagnosis(),
      right: this.rightDiagnosis.getDiagnosis(),
      session_id: this.session.session.id,
    })).subscribe({
      next: () => {
        console.log('PostDiagnosis next');
        this.completed.next(State.Completed);
        this.snackBar.open('Saved diagnosis', undefined, {
          duration: 3000
        });
      },
      error: (err) => {
        this.snackBar.open('Something went wrong', undefined, {
          duration: 3000
        });
      },
      complete: () => { console.log('PostDiagnosis complete'); },
    });
  }

  OnSkip() {
    console.debug('onSkip');

    const cfg: ConfirmDialogConfig = {
      header: 'Skip session?',
      body: ``,
      ok_string: 'Yes',
      cancel_string: 'No',
    };
    const dialogRef = this.confirm_dialog.open(ConfirmDialogComponent, { data: cfg });
    dialogRef.afterClosed().subscribe(ok => {
      if (!ok) { return; }
      this.dxService.SkipSessions({session_ids: [this.session.session.id]}).subscribe({
        next: () => {
          console.log('SkipSessions next');
          this.completed.next(State.Completed);
          this.snackBar.open('Skipped session', undefined, {
            duration: 3000
          });
        },
        error: (err) => {
          this.snackBar.open('Something went wrong', undefined, {
            duration: 3000
          });
        },
        complete: () => { console.log('SkipSessions complete complete'); },
      });
    });
  }

  hasUnsavedChanges(): boolean {
    return (this.leftDiagnosis.required && this.leftDiagnosis.completed()) ||
      (this.rightDiagnosis.required && this.rightDiagnosis.completed());
  }

  isReadyToSave(): boolean {
    return this.leftDiagnosis.completed() && this.rightDiagnosis.completed();
  }

  getEarBadge(img: Image): string {
    return getEarBadge(img);
  }

  imgDxCompleted(img: Image): boolean {
    return isDiagnosisCompleted(img, this.leftDiagnosis, this.rightDiagnosis);
  }

  imgURL(img: Image): string {
    if (img.thumbnail_url) {
      return img.thumbnail_url;
    }
    if (img.url) {
      return img.url;
    }
    return '/assets/baseline-remove_circle-24px.svg'; // TODO(austin): replace with another image
  }

  clearSession() {
    if (!this.hasUnsavedChanges()) {
      this.completed.next(State.Closed);
      return;
    }

    const cfg: ConfirmDialogConfig = {
      header: 'Cancel session diagnosis?',
      body: `CANCEL will discard all diagnoses you have attached to images and the session will
remain in the list to be diagnosed. Are you sure you want to CANCEL?`,
      ok_string: 'Yes',
      cancel_string: 'No',
    };
    const dialogRef = this.confirm_dialog.open(ConfirmDialogComponent, {data: cfg});
    dialogRef.afterClosed().subscribe(ok => {
      if (!ok) { return; }
      this.leftDiagnosis.reset();
      this.rightDiagnosis.reset();
      this.completed.next(State.Closed);
    });
  }

  openDialog(idx: number) {
    console.debug('openDialog');
    const dialogRef = this.dialog.open(SessionDetailsComponent, {
      width: '500px',
      data: {
        leftDx: this.leftDiagnosis,
        rightDx: this.rightDiagnosis,
        session_data: this.session,
        index: idx,
      },
    });
    dialogRef.afterClosed().subscribe(r => {
      console.debug('dialog closed');
      if (!r) { return; }
      this.leftDiagnosis = r.leftDx;
      this.rightDiagnosis = r.rightDx;
    });
  }

  imgError(idx: number) {
    if (this.session.images[idx].thumbnail_url) {
      this.session.images[idx].thumbnail_url = null;
      return;
    }
    this.session.images[idx].url = null;
  }

  openSession() {
    this.completed.next(State.Opened);
  }
}

function boolDisplay(b?: any): string {
  if (b === true || b === 1) {
    return 'Yes';
  }
  if (b === false || b === 0) {
    return 'No';
  }
  return 'N/A';
}

interface SessionSummary {
  left_count: number;
  right_count: number;
  left_diagnosed: Image[];
  right_diagnosed: Image[];
}

const XSmall = '(max-width: 799.99px)';
const Small = '(min-width: 800px) and (max-width: 1199.99px)';
const Medium = '(min-width: 1200px) and (max-width: 1599.99px)';
const Large = '(min-width: 1600px) and (max-width: 1919.99px)';
const XLarge = '(min-width: 1920px)';

export enum State {
  Closed = 1,
  Completed = 2,
  Opened = 3,
}

export enum OpenState {
  Blocked = 1,
  CanOpen = 2,
  Open = 3,
}
