
<div *ngIf="global">
  <h1>Global Statistics</h1>
  <table>
    <tr *ngFor="let pair of global | keyvalue">
      <td class="key">{{ dxString(+pair.key) }}</td>
      <td class="number">{{ pair.value }}</td>
    </tr>
  </table>
</div>

<div *ngIf="member">
  <h1>Researcher Statistics</h1>

  <table>
    <tr>
      <td class="key">Rank</td>
      <td class="number">{{ member.rank }}</td>
    </tr>
    <tr>
      <td class="key">Assigned</td>
      <td class="number">{{ member.assigned }}</td>
    </tr>
    <tr>
      <td class="key">Sessions Completed</td>
      <td class="number">{{ member.sessions_completed }}</td>
    </tr>
    <tr>
      <td class="key">Images Diagnosed</td>
      <td class="number">{{ member.images_diagnosed }}</td>
    </tr>
  </table>

  <h2>Images per diagnosis</h2>
  <table>
    <tr *ngFor="let pair of member.diagnosis_counts | keyvalue">
      <td class="key">{{ dxString(+pair.key) }}</td>
      <td class="number">{{ pair.value }}</td>
    </tr>
  </table>
</div>
