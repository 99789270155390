import { ComponentFactoryResolver, Injector, ApplicationRef, ComponentRef } from '@angular/core';
import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { LightboxViewComponent } from '../shared/components/lightbox-view/lightbox-view.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var LightboxService = /** @class */ (function () {
    function LightboxService(
    // inject document ref and deps needed by DOM portal host
    document, appRef, componentFactoryResolver, injector) {
        this.document = document;
        this.appRef = appRef;
        this.componentFactoryResolver = componentFactoryResolver;
        this.injector = injector;
        // create portal
        this.lightboxContainerPortal = new ComponentPortal(LightboxViewComponent);
        // create anchor for portal host to body tag
        this.bodyPortalHost = new DomPortalHost(document.body, this.componentFactoryResolver, this.appRef, this.injector);
    }
    LightboxService.prototype.show = function (id) {
        var _this = this;
        // attach portal to host
        var componentRef = this.bodyPortalHost.attach(this.lightboxContainerPortal);
        componentRef.instance.imageId = id;
        // subscribe to close event
        componentRef.instance.close.subscribe(function () { return _this.hide(); });
    };
    LightboxService.prototype.hide = function () {
        // detach portal form host
        this.bodyPortalHost.detach();
    };
    LightboxService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LightboxService_Factory() { return new LightboxService(i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.INJECTOR)); }, token: LightboxService, providedIn: "root" });
    return LightboxService;
}());
export { LightboxService };
