

<mat-card>
  <mat-card-title>
    {{ session.session.name }} ({{ session.session.id }})
    <button *ngIf="state == OpenState.CanOpen"
      mat-flat-button color="primary"
      (click)="openSession()">View</button>
  </mat-card-title>
  <mat-card-subtitle>
    {{ (session.session.start_time * 1000) | date }} |
    {{ session.images.length | i18nPlural: pluralMap }}
  </mat-card-subtitle>

  <div *ngIf="state == OpenState.Open">
    <div class="metadata-table">
      <table>
        <tr>
          <td>Age</td>
          <td>{{ patientMetadata.age }}</td>
        </tr>
        <tr>
          <td>Gender</td>
          <td>{{ patientMetadata.gender }}</td>
        </tr>
      </table>
    </div>

    <div class="metadata-table">
      <table>
        <tr>
          <td>Ear Pain</td>
          <td>{{ patientMetadata.ear_pain }}</td>
        </tr>
        <tr>
          <td>Fever</td>
          <td>{{ patientMetadata.fever }}</td>
        </tr>
        <tr>
          <td>Hearing Loss</td>
          <td>{{ patientMetadata.hearing_loss }}</td>
        </tr>
        <tr>
          <td>Discharge</td>
          <td>{{ patientMetadata.discharge }}</td>
        </tr>
      </table>
    </div>

    <mat-grid-list [cols]="ncols" gutterSize="16" rowHeight="280">
      <mat-grid-tile *ngFor="let im of session.images; let i = index">
        <div class="thumbnail-container2">
          <span class="thumbnail-container" (click)="openDialog(i)">
            <img class="thumbnail" [src]="imgURL(im)" (error)="imgError(i)">
          </span>
          <span class="badge ear-badge-{{ getEarBadge(im) }}">{{ getEarBadge(im)[0] | uppercase }}</span>
          <span *ngIf="im.has_diagnosis" class="badge">
            <mat-icon>subject</mat-icon>
          </span>
          <span *ngIf="imgDxCompleted(im)" class="badge">
            <mat-icon>check</mat-icon>
          </span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-card-actions>
      <button mat-flat-button color="secondary"
        (click)="clearSession()"
        style="margin-right:0.5em"
      >Cancel</button>
      <button mat-flat-button color="primary"
        [disabled]="!isReadyToSave()"
        [matTooltip]="!isReadyToSave() ? 'You must diagnose each available ear before saving' : null"
        (click)="OnSave()"
      >Save</button>
      <button mat-flat-button color="secondary"
        (click)="OnSkip()"
      >Skip</button><br />
    </mat-card-actions>
  </div>
</mat-card>
