import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  header?: string;
  body?: string;
  ok_string = 'OK';
  cancel_string = 'Cancel';

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogConfig) {
    this.header = data.header;
    this.body = data.body;
    if (data.ok_string) { this.ok_string = data.ok_string; }
    if (data.cancel_string) { this.cancel_string = data.cancel_string; }
  }

  ngOnInit() {
  }

}

export interface ConfirmDialogConfig {
  header?: string;
  body?: string;
  ok_string?: string;
  cancel_string?: string;
}
