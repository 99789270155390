<div class="wrapper mat-elevation-z8">
  <div style="padding: 16px;">
    <app-chip-filter [allTags]="allTags"></app-chip-filter>
  </div>
  <mat-divider></mat-divider>

  <div class="menu-list">
    <div class="sub-menu-list">
      <mat-nav-list>
        <a #listItemsRef mat-list-item (click)="getDataTag(this.listItems[i])" [attr.data-display-name]="item.display_name" *ngFor="let item of listItems; let i = index">
          {{ item.display_name }} </a>
      </mat-nav-list>
    </div>
    
    <!--  TODO: can probably be refactored to be more dynamic -->
    <div class="sub-menu-list ">
      <div *ngIf="menuToShow === 'Sessions'">
        <mat-selection-list dense #filterList [(ngModel)]="selectedTags" (ngModelChange)="onNgModelChange($event)">
          <mat-list-option checkboxPosition="before" *ngFor="let tag of allSessionTags" [value]="tag">
            {{ tag }}
          </mat-list-option>
        </mat-selection-list>
      </div>

      <div *ngIf="menuToShow === 'Patients'">
        <mat-selection-list dense #filterList [(ngModel)]="selectedTags" (ngModelChange)="onNgModelChange($event)">
          <mat-list-option checkboxPosition="before" *ngFor="let tag of allPatientTags" [value]="tag">
            {{ tag }}
          </mat-list-option>
        </mat-selection-list>
      </div>

      <div *ngIf="menuToShow === 'Something'">
        <mat-selection-list dense #filterList [(ngModel)]="selectedTags" (ngModelChange)="onNgModelChange($event)">
          <mat-list-option checkboxPosition="before" *ngFor="let tag of ['assa','asda','dda']" [value]="tag">
            {{ tag }}
          </mat-list-option>
        </mat-selection-list>
      </div>

    </div>
  </div>

  <mat-divider></mat-divider>

  <div style="display: flex; padding: 16px;">
    <button mat-button (click)="cancelFilter()">CANCEL</button>
    <span style="flex: 1 1 auto;"></span>
    <button mat-raised-button color="primary" (click)="applyFilter()" color="primary">APPLY</button>
  </div>


</div>
