<mat-toolbar>
  <button cdk-overlay-origin style="margin: 0 10px;"
   mat-button (click)="openFilterPanel()" >
    <mat-icon>filter_list</mat-icon> Filter
  </button>

  <span style="flex: 1 1 auto;"></span>

</mat-toolbar>

<div class="gallery-container" >
  <div *ngFor="let item of sessions" class="gallery-grid">
    <app-gallery-item (click)="openLightboxOverlay(item.id)" [currentItem]="item" ></app-gallery-item>
  </div>
</div>


