import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
// import * as authenticatedRoutes from './auth.routes';
import {} from '@angular/material';
import { AuthenticatedComponent } from './authenticated.component';
import { SharedModule } from '../shared/shared.module';
import { HardwareService } from '../services/hardware.service';
import { DataTableComponent } from '../authenticated/data-table/data-table.component';
import { GalleryViewComponent } from './gallery-view/gallery-view.component';
import { LightboxViewComponent } from '../shared/components/lightbox-view/lightbox-view.component';
import { LightboxService } from '../services/lightbox.service';
import { GalleryService } from '../services/gallery.service';
import { GalleryModule } from './gallery-view/gallery.module';
import { FilterMenuComponent } from '../shared/components/filter-menu/filter-menu.component';
import { SessionViewComponent } from './session-view/session-view.component';
import { SessionDiagnoseViewComponent } from './session-diagnose-view/session-diagnose-view.component';
import { SessionDetailsComponent } from './session-details/session-details.component';
import { ValidationListComponent } from './validation-list/validation-list.component';
import { AuthGuard } from '../auth/auth.guard';
import { LoginComponent } from '../auth/login/login.component';
import { StatisticsComponent } from './statistics/statistics.component';

export const authRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'gallery', component: GalleryViewComponent },
  { path: 'data-table', component: DataTableComponent },
  { path: 'sessions', component: SessionViewComponent, canActivate: [AuthGuard] },
  { path: 'validate_images', component: ValidationListComponent },
  { path: 'statistics', component: StatisticsComponent },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(authRoutes), SharedModule, GalleryModule, HttpClientModule],
  exports: [CommonModule, AuthenticatedComponent],
  declarations: [
    DataTableComponent,
    AuthenticatedComponent,
    SessionViewComponent,
    SessionDiagnoseViewComponent,
    SessionDetailsComponent,
    ValidationListComponent,
    LoginComponent,
    StatisticsComponent,
  ],
  providers: [HardwareService],
  entryComponents: [FilterMenuComponent, SessionDetailsComponent]
})
export class AuthenticatedModule {}
