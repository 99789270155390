import { Component, OnInit } from '@angular/core';
import { DiagnosisService } from '../../services/diagnosis.service';
import { SessionWithImages } from '../../models/diagnosis';
import { State, OpenState } from '../session-diagnose-view/session-diagnose-view.component';

@Component({
  selector: 'app-session-view',
  templateUrl: './session-view.component.html',
  styleUrls: ['./session-view.component.css']
})
export class SessionViewComponent implements OnInit {
  sessions: SessionWithImages[] = [];
  openedSession: number | undefined;
  isLoading = false;

  constructor(private dxsvc: DiagnosisService) { }

  ngOnInit() {
    this.refreshSessions();
  }

  refreshSessions() {
    this.isLoading = true;
    this.dxsvc.GetSessions().subscribe(res => {
      this.isLoading = false;
      this.sessions = res;
    });
  }

  openSession(i: number) {
    console.log('openSession', i);
    if (this.openedSession !== undefined) {
      return;
    }
    this.openedSession = i;
  }

  onSessionStateChange(i: number, state: number) {
    if (this.openedSession === undefined && state === State.Opened) {
      this.openedSession = i;
      return;
    }

    if (i !== this.openedSession) {
      // not the currently opened session, ignore
      return;
    }

    if (state === State.Completed) {
      this.openedSession = undefined;
      this.refreshSessions();
    } else if (state === State.Closed) {
      this.openedSession = undefined;
    }
  }

  getState(i: number): OpenState {
    if (i === this.openedSession) {
      return OpenState.Open;
    }
    if (this.openedSession === undefined) {
      return OpenState.CanOpen;
    }
    return OpenState.Blocked;
  }
}
