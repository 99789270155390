

<mat-form-field class="chip-list">
  <mat-chip-list #chipList>

    <mat-chip *ngFor="let tag of tags" [removable]="removable" (removed)="remove(tag)">
      {{tag}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>

    <input placeholder="Filter using tags" 
      #tagInput [formControl]="tagCtrl" 
      [matAutocomplete]="auto" 
      [matChipInputFor]="chipList" 
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur" 
      (matChipInputTokenEnd)="add($event)" 
    />
  </mat-chip-list>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      {{ tag }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
