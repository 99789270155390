<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport="true"
    [attr.role]="isHandset ? 'dialog' : 'navigation'"
    [mode]="isHandset ? 'over' : 'side'"
    [opened]="!isHandset">
    <mat-toolbar color="primary">Menu</mat-toolbar>
    <mat-nav-list>
      <!-- <a mat-list-item routerLink="/data-table">Data table</a> -->
      <!-- <a mat-list-item routerLink="/gallery">Gallery</a> -->
      <a mat-list-item routerLink="/sessions">Sessions</a>
      <a mat-list-item routerLink="/validate_images">Validate Images</a>
      <a mat-list-item routerLink="/statistics">Statistics</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>hearScope Image Diagnosis</span>
      <span class="spacer"></span>

      <span *ngIf="stats" class="normal">Rank: {{ stats.rank }}</span>
      <span *ngIf="stats" class="normal">Images diagnosed: {{ stats.images_diagnosed }}</span>
      <span *ngIf="user" class="normal">{{ user.email }} ({{ user.id }})</span>
      <button mat-flat-button *ngIf="user" (click)="logout()">Logout</button>

      <span *ngIf="!user" class="normal">Not logged in</span>
    </mat-toolbar>
  </mat-sidenav-content>
</mat-sidenav-container>
