import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
// jwtKey is the key used to store the jwt in localStorage
var jwtKey = 'jwt';
var AuthapiService = /** @class */ (function () {
    function AuthapiService(http) {
        this.http = http;
        this.endpoint = environment.authEndpoint;
        this.isLoggedIn = false;
        this.user$ = new Subject();
        console.log('constructor');
        var ok = this.checkLoggedIn();
        console.log(ok);
    }
    AuthapiService.prototype.checkLoggedIn = function () {
        if (this.isLoggedIn) {
            return true;
        }
        var token = localStorage.getItem(jwtKey);
        if (!token) {
            return false;
        }
        var user;
        try {
            user = this.parseToken(token);
        }
        catch (err) {
            if (err.message === 'jwt is expired') {
                console.log('expired token');
            }
            else {
                console.error('error parsing token ', err);
            }
            localStorage.removeItem(jwtKey);
            user = undefined;
            return false;
        }
        this.isLoggedIn = true;
        this.token = token;
        this.user = user;
        this.user$.next(this.user); // TODO(austin): remove?
        return true;
    };
    AuthapiService.prototype.login = function (username, password) {
        var _this = this;
        return this.http.post(this.endpoint + "/login", {
            email: username,
            password: password,
        }).pipe(map(function (v) { return v.data.token; }), tap(function (token) {
            try {
                _this.user = _this.parseToken(token);
                _this.isLoggedIn = true;
                _this.token = token;
                localStorage.setItem(jwtKey, token);
                _this.user$.next(_this.user);
            }
            catch (e) {
                // const er: Error = e;
                console.error('invalid token', e);
            }
        }));
    };
    AuthapiService.prototype.logout = function () {
        this.isLoggedIn = false;
        this.token = '';
        this.user = undefined;
        localStorage.removeItem(jwtKey);
        this.user$.next(this.user);
    };
    AuthapiService.prototype.parseToken = function (s) {
        var parts = s.split('.');
        if (parts.length !== 3) {
            throw new Error('incorrect number of parts');
        }
        var acc = JSON.parse(atob(parts[1]));
        if (new Date().getTime() / 1000 > acc.exp) {
            throw new Error('jwt is expired');
        }
        var id = parseInt(acc.id, 10);
        return {
            id: id,
            email: acc.email,
        };
    };
    AuthapiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthapiService_Factory() { return new AuthapiService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthapiService, providedIn: "root" });
    return AuthapiService;
}());
export { AuthapiService };
