import { Component } from '@angular/core';

@Component({
  selector: 'app-authenticated',
  template: `
    <navigation></navigation>
    <div class="router-wrapper">
      <router-outlet></router-outlet>
    </div>`,
  styleUrls: ['./authenticated.component.scss'],
})
export class AuthenticatedComponent {}
