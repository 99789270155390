import { Router } from '@angular/router';
import { AuthapiService } from '../services/authapi.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/authapi.service";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authsvc, router) {
        this.authsvc = authsvc;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        return this.checkLogin(state.url);
    };
    AuthGuard.prototype.checkLogin = function (url) {
        if (this.authsvc.checkLoggedIn()) {
            return true;
        }
        this.authsvc.redirectUrl = url;
        this.router.navigate(['/login']);
        return false;
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthapiService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
