import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  HostListener,
} from '@angular/core';
import { Overlay, OverlayConfig, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';

import { LightboxService } from '../../services/lightbox.service';
import { GalleryService, SessionData } from '../../services/gallery.service';
import { LightboxViewComponent } from '../../shared/components/lightbox-view/lightbox-view.component';
import { MatTableDataSource } from '@angular/material';
import { ChipFilterComponent } from '../../shared/components/chip-filter/chip-filter.component';
import { FilterMenuComponent } from '../../shared/components/filter-menu/filter-menu.component';
import { FilterMenuService } from '../../services/filter-menu.service';
import { FilterMenuRemote } from '../../shared/components/filter-menu/filter-menu-remote';

@Component({
  selector: 'app-gallery-view',
  templateUrl: './gallery-view.component.html',
  styleUrls: ['./gallery-view.component.scss'],
})
export class GalleryViewComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(CdkOverlayOrigin, { static: false }) overlayOrigin: CdkOverlayOrigin;

  filterRemote: FilterMenuRemote;
  filterPanelOpen: boolean;
  sessions: SessionData[];
  chips: string[];

  constructor(
    private lightboxService: LightboxService,
    private filterMenuService: FilterMenuService,
    private galleryService: GalleryService,
  ) {}

  ngOnInit() {
    this.getSessions();
  }

  openFilterPanel() {
    this.filterPanelOpen = true;
    this.filterRemote = this.filterMenuService.open(this.overlayOrigin);
  }

  closeFilterPanel() {
    this.filterPanelOpen = false;
    this.filterRemote.close();
  }

  getSessions(filter?: string) {
    return this.galleryService
      .getSessionData()
      .subscribe(res => (this.sessions = res));
  }

  openLightboxOverlay(id) {
    console.log('Working', id);
    this.lightboxService.show(id);
  }

  applyFilter(tag: string[]) {
    console.log('Listen here!');
    this.galleryService.filterSessionsByTag(tag);
  }

  cancelFilter(): void {
    this.closeFilterPanel();
  }
}
