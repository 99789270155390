/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./validation-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../../node_modules/@angular/material/grid-list/typings/index.ngfactory";
import * as i6 from "@angular/material/grid-list";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/common";
import * as i9 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "./validation-list.component";
import * as i13 from "../../services/diagnosis.service";
import * as i14 from "@angular/material/dialog";
import * as i15 from "@angular/cdk/layout";
var styles_ValidationListComponent = [i0.styles];
var RenderType_ValidationListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ValidationListComponent, data: {} });
export { RenderType_ValidationListComponent as RenderType_ValidationListComponent };
function View_ValidationListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ValidationListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-grid-tile", [["class", "mat-grid-tile"]], null, null, null, i5.View_MatGridTile_0, i5.RenderType_MatGridTile)), i1.ɵdid(1, 49152, [[1, 4]], 0, i6.MatGridTile, [i1.ElementRef, [2, i6.ɵa11]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["class", "thumbnail-container2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleValid(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "thumbnail-container"]], [[2, "invalid", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "thumbnail"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_v.context.$implicit.valid; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.imgURL(_v.context.$implicit); _ck(_v, 4, 0, currVal_1); }); }
function View_ValidationListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click on images to mark them as invalid. Invalid images are shown with a red border. Click [Next] to submit this batch of images and get the next batch."])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "mat-grid-list", [["class", "mat-grid-list"], ["gutterSize", "16"], ["rowHeight", "250"]], null, null, null, i5.View_MatGridList_0, i5.RenderType_MatGridList)), i1.ɵprd(6144, null, i6.ɵa11, null, [i6.MatGridList]), i1.ɵdid(5, 2211840, null, 1, i6.MatGridList, [i1.ElementRef, [2, i7.Directionality]], { cols: [0, "cols"], gutterSize: [1, "gutterSize"], rowHeight: [2, "rowHeight"] }, null), i1.ɵqud(603979776, 1, { _tiles: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ValidationListComponent_3)), i1.ɵdid(8, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["color", "primary"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i10.MatButton, [i1.ElementRef, i11.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Next"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["style", "margin-left:1em"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", " images remaining"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ncols; var currVal_1 = "16"; var currVal_2 = "250"; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.images; _ck(_v, 8, 0, currVal_3); var currVal_6 = "primary"; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (i1.ɵnov(_v, 10).disabled || null); var currVal_5 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_7 = (_co.unvalidated_count - _co.images.length); _ck(_v, 13, 0, currVal_7); }); }
function View_ValidationListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No images to validate."]))], null, null); }
export function View_ValidationListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidationListComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidationListComponent_2)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidationListComponent_4)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.images.length > 0); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.images.length == 0) && !_co.loading); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ValidationListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-validation-list", [], null, null, null, View_ValidationListComponent_0, RenderType_ValidationListComponent)), i1.ɵdid(1, 245760, null, 0, i12.ValidationListComponent, [i13.DiagnosisService, i14.MatDialog, i15.BreakpointObserver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ValidationListComponentNgFactory = i1.ɵccf("app-validation-list", i12.ValidationListComponent, View_ValidationListComponent_Host_0, {}, {}, []);
export { ValidationListComponentNgFactory as ValidationListComponentNgFactory };
