<div class="wrapper">

  <button mat-fab aria-label="Previous image" (click)="prevImage()" style="margin-right:1em;">
    <mat-icon>chevron_left</mat-icon>
  </button>

  <span class="expand-detail expand">
    <table>
      <tr>
        <td>Discharge</td>
        <td>{{ patientMetadata.discharge }}</td>
      </tr>
      <tr>
        <td>Ear Pain</td>
        <td>{{ patientMetadata.ear_pain }}</td>
      </tr>
      <tr>
        <td>Fever</td>
        <td>{{ patientMetadata.fever }}</td>
      </tr>
      <tr>
        <td>Hearing Loss</td>
        <td>{{ patientMetadata.hearing_loss }}</td>
      </tr>
      <tr>
        <td>Gender</td>
        <td>{{ patientMetadata.gender }}</td>
      </tr>
      <tr>
        <td>Age</td>
        <td>{{ patientMetadata.age }}</td>
      </tr>
    </table>
    <mat-list-item role="listitem">Notes: {{ currentImage().note }}</mat-list-item>
  </span>

  <span class="image-wrapper">
    <span class="close-icon" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </span>
    <mat-card (blur)="closeDialog()">
      <mat-card-subtitle>{{ index + 1 }} of {{ data.session_data.images.length }}</mat-card-subtitle>
      <img mat-card-image class="full-size" [src]="imgURL(currentImage())" alt="image - {{ currentImage().id }}" (error)="imgError($event, index)">
      <mat-card-actions>
        <span>{{ dxText() }}</span><br />
        <button mat-flat-button color="primary" (click)="openDiagnosisPanel()" [disabled]="!currentImage().can_diagnose">{{ dxButtonText() }}</button>
      </mat-card-actions>
    </mat-card>

    <div class="badge-list">
      <span class="badge ear-badge-{{ getEarBadge(currentImage()) }}">{{ getEarBadge(currentImage())[0] | uppercase }}</span>
      <span *ngIf="currentImage().has_diagnosis" class="badge"><mat-icon>subject</mat-icon></span>
      <span *ngIf="imgDxCompleted(currentImage())" class="badge"><mat-icon>check</mat-icon></span>
    </div>

  </span>

  <span class="expand-detail" [class.expand]="showDxPanel" #expandDetail (keyup)="$event.stopPropagation()">
    <span>Diagnosis</span>
    <form [formGroup]="dxform">
      <mat-radio-group formControlName="dx">
        <mat-radio-button value="1">Normal</mat-radio-button><br/>
        <mat-radio-button value="2">Wax or Obstruction</mat-radio-button><br/>
        <mat-radio-button value="11">Foreign Bodies</mat-radio-button><br/>
        <mat-radio-button value="10">Grommets</mat-radio-button><br/>
        <mat-radio-button value="9">Myringosclerosis</mat-radio-button><br/>
        <mat-radio-button value="3">Acute Otitis Media</mat-radio-button><br/>
        <mat-radio-button value="4">Otitis Media With Effusion</mat-radio-button><br/>
        <mat-radio-button value="5">Dry Chronic Central Perforation</mat-radio-button><br/>
        <mat-radio-button value="6">Wet Chronic Central Perforation</mat-radio-button><br/>
        <mat-radio-button value="7">Retraction Pockets</mat-radio-button><br/>
        <mat-radio-button value="8">Cholesteatoma</mat-radio-button><br/>
        <mat-radio-button value="0">Unspecified</mat-radio-button><br/>
      </mat-radio-group>
    </form>
  </span>

  <button #dxbtn mat-fab aria-label="Previous image" (click)="nextImage()" style="margin-left:1em;">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
