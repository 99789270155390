/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./authenticated.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/components/navigation/navigation.component.ngfactory";
import * as i3 from "../shared/components/navigation/navigation.component";
import * as i4 from "@angular/cdk/layout";
import * as i5 from "../services/authapi.service";
import * as i6 from "../services/diagnosis.service";
import * as i7 from "@angular/router";
import * as i8 from "./authenticated.component";
var styles_AuthenticatedComponent = [i0.styles];
var RenderType_AuthenticatedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthenticatedComponent, data: {} });
export { RenderType_AuthenticatedComponent as RenderType_AuthenticatedComponent };
export function View_AuthenticatedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navigation", [], null, null, null, i2.View_NavigationComponent_0, i2.RenderType_NavigationComponent)), i1.ɵdid(1, 180224, null, 0, i3.NavigationComponent, [i4.BreakpointObserver, i5.AuthapiService, i6.DiagnosisService, i7.Router], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "router-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
export function View_AuthenticatedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-authenticated", [], null, null, null, View_AuthenticatedComponent_0, RenderType_AuthenticatedComponent)), i1.ɵdid(1, 49152, null, 0, i8.AuthenticatedComponent, [], null, null)], null, null); }
var AuthenticatedComponentNgFactory = i1.ɵccf("app-authenticated", i8.AuthenticatedComponent, View_AuthenticatedComponent_Host_0, {}, {}, []);
export { AuthenticatedComponentNgFactory as AuthenticatedComponentNgFactory };
