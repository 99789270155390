import { Directive, HostListener, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[screenSize]',
})
export class ScreenSizeDirective implements OnInit {
  @Output() windowSizeChanged: EventEmitter<number> = new EventEmitter();
  sidebarVisible: boolean;
  screenWidth: number;
  title = 'app';
  @Input() isMobile: boolean;
  maxScreenSize = 800;

  constructor() {}

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= this.maxScreenSize) {
      this.isMobile = true;
      this.sidebarVisible = false;
      console.log('-->', this.isMobile);
    } else {
      this.isMobile = false;
      this.sidebarVisible = true;
      console.log('-->', this.isMobile);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    this.screenWidth = e.target.innerWidth;
    this.windowSizeChanged.emit(this.screenWidth);
    if (this.screenWidth <= this.maxScreenSize) {
      this.isMobile = true;
      this.sidebarVisible = false;
    } else {
      this.isMobile = false;
      this.sidebarVisible = true;
    }
  }
}
