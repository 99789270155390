<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

<div *ngFor="let sn of sessions; let i = index" class="session">
  <app-session-diagnose-view
    [session]="sn"
    [state]="getState(i)"
    (completed)="onSessionStateChange(i, $event)"
  ></app-session-diagnose-view>
</div>

<div *ngIf="sessions.length == 0 && !isLoading">
  <p>You don't have any sessions to diagnose right now.</p>
</div>
