import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { FilterMenuComponent } from '../shared/components/filter-menu/filter-menu.component';
import { FilterMenuRemote } from '../shared/components/filter-menu/filter-menu-remote';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
var FilterMenuService = /** @class */ (function () {
    function FilterMenuService(overlay) {
        this.overlay = overlay;
    }
    FilterMenuService.prototype.open = function (overlayOrigin) {
        var filterPositionStrategy = this.overlay
            .position()
            .connectedTo(overlayOrigin.elementRef, { originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' });
        var config = new OverlayConfig({
            positionStrategy: filterPositionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.close(),
            hasBackdrop: true,
            backdropClass: 'trans-backdrop',
        });
        this.overlayRef = this.overlay.create(config);
        var filterMenuPortal = new ComponentPortal(FilterMenuComponent);
        this.overlayRef.attach(filterMenuPortal);
        this.overlayRef.backdropClick().subscribe(function (_) { return filterMenuRemote.close(); });
        var filterMenuRemote = new FilterMenuRemote(this.overlayRef);
        return filterMenuRemote;
    };
    FilterMenuService.prototype.close = function () {
        this.overlayRef.dispose();
    };
    FilterMenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FilterMenuService_Factory() { return new FilterMenuService(i0.ɵɵinject(i1.Overlay)); }, token: FilterMenuService, providedIn: "root" });
    return FilterMenuService;
}());
export { FilterMenuService };
