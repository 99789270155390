/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./session-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../session-diagnose-view/session-diagnose-view.component.ngfactory";
import * as i6 from "../session-diagnose-view/session-diagnose-view.component";
import * as i7 from "../../services/diagnosis.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/cdk/layout";
import * as i10 from "@angular/material/snack-bar";
import * as i11 from "@angular/common";
import * as i12 from "./session-view.component";
var styles_SessionViewComponent = [i0.styles];
var RenderType_SessionViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SessionViewComponent, data: {} });
export { RenderType_SessionViewComponent as RenderType_SessionViewComponent };
function View_SessionViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_SessionViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "session"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-session-diagnose-view", [], null, [[null, "completed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("completed" === en)) {
        var pd_0 = (_co.onSessionStateChange(_v.context.index, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SessionDiagnoseViewComponent_0, i5.RenderType_SessionDiagnoseViewComponent)), i1.ɵdid(2, 245760, null, 0, i6.SessionDiagnoseViewComponent, [i7.DiagnosisService, i8.MatDialog, i8.MatDialog, i9.BreakpointObserver, i10.MatSnackBar], { session: [0, "session"], state: [1, "state"] }, { completed: "completed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.getState(_v.context.index); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SessionViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You don't have any sessions to diagnose right now."]))], null, null); }
export function View_SessionViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SessionViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SessionViewComponent_2)), i1.ɵdid(3, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SessionViewComponent_3)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.sessions; _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.sessions.length == 0) && !_co.isLoading); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_SessionViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-session-view", [], null, null, null, View_SessionViewComponent_0, RenderType_SessionViewComponent)), i1.ɵdid(1, 114688, null, 0, i12.SessionViewComponent, [i7.DiagnosisService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionViewComponentNgFactory = i1.ɵccf("app-session-view", i12.SessionViewComponent, View_SessionViewComponent_Host_0, {}, {}, []);
export { SessionViewComponentNgFactory as SessionViewComponentNgFactory };
