import { Component, OnInit } from '@angular/core';
import { Diagnosis, DiagnosisCount, MemberStats, DiagnosisString } from '../../models/diagnosis';
import { DiagnosisService } from '../../services/diagnosis.service';
import { AuthapiService } from '../../services/authapi.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {
  global: DiagnosisCount;
  member: MemberStats;

  constructor(
    private dxsvc: DiagnosisService,
    private authsvc: AuthapiService,
  ) { }

  ngOnInit() {
    this.dxsvc.GetGlobalStats().subscribe(r => {
      this.global = r;
    });
    if (this.authsvc.checkLoggedIn()) {
      this.dxsvc.GetMemberStats().subscribe(r => {
        this.member = r;
      });
    }
  }

  dxString(d: Diagnosis): string {
    return DiagnosisString(d);
  }

}
