import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Image, SessionWithImages, PostDiagnosisReq, AddImageValidationReq, MemberStats, DiagnosisCount, ValidationImagesRsp } from '../models/diagnosis';
import { AuthapiService } from './authapi.service';

@Injectable({
    providedIn: 'root',
})
export class DiagnosisService {
    endpoint: string = environment.diagnosisEndpoint;

    constructor(private http: HttpClient, private authsvc: AuthapiService) {}

    GetSessions(): Observable<SessionWithImages[]> {
        console.log('GetSessions');
        // TEMP(austin): use rearcher_id 1 for now, API should infer it from authentication token
        const res = this.http.post<APIResult<SessionWithImages[]>>(
            this.endpoint + '/v1/get_new_sessions',
            null, { headers: this.authHeaders() }
        ).pipe(unwrap2);
        return res;
    }

    PostDiagnosis(req: PostDiagnosisReq): Observable<APIResult<void>> {
        return this.http.post<APIResult<void>>(
            this.endpoint + '/v1/diagnosis',
            req,
            { headers: this.authHeaders() }
        );
    }

    SkipSessions(req: {session_ids: number[]}): Observable<void> {
        return this.http.post<APIResult<void>>(
            `${this.endpoint}/v1/sessions_skip`,
            req,
            { headers: this.authHeaders() },
        ).pipe(unwrap2);
    }

    GetImagesToValidate(): Observable<ValidationImagesRsp> {
        return this.http.get<APIResult<ValidationImagesRsp>>(
            `${this.endpoint}/v1/vimgs`
        ).pipe(unwrap2);
    }

    AddImageValidation(req: AddImageValidationReq): Observable<void> {
        return this.http.patch<APIResult<void>>(`${this.endpoint}/v1/image/validate`, req).pipe(unwrap2);
    }

    GetMemberStats(): Observable<MemberStats> {
        return this.http.get<APIResult<MemberStats>>(
            `${this.endpoint}/v1/member_stats`,
            { headers: this.authHeaders() },
        ).pipe(unwrap2);
    }

    GetGlobalStats(): Observable<DiagnosisCount> {
        return this.http.get<APIResult<DiagnosisCount>>(
            `${this.endpoint}/v1/global_stats`,
            { headers: this.authHeaders() },
        ).pipe(unwrap2);
    }

    authHeaders(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'token ' + this.authsvc.token,
        });
    }
}

function unwrap<T>(rsp: APIResult<T>): T {
    return rsp.data;
}

function unwrap2<T>(source: Observable<APIResult<T>>): Observable<T> {
    return new Observable(observer => {
        return source.subscribe({
            next(x) { observer.next(x.data); },
            error(err) { observer.error(err); },
            complete() { observer.complete(); },
        });
    });
}

export class APIResult<T> {
    timestamp: string;
    code: string;
    message?: string;
    data: T;
}

// r.Get("/vimgs", handlers.Handler(c, GetImagesToValidate))
// r.Patch("/image/validate", handlers.Handler(c, AddImageValidation))

// // debug
// r.Post("/freeze_sessions", handlers.Handler(c, FreezeSessions))
// r.Get("/session_stats", handlers.Handler(c, GetSessionStats))
// r.Post("/update_session_validations", handlers.Handler(c, UpdateSessionValidations))
// r.Post("/get_new_sessions", handlers.Handler(c, AssignNewSessionsToResearcher))
// r.Post("/diagnosis", handlers.Handler(c, PostDiagnosis))
