/* Do not change, this code is generated from Golang structs */

type Timestamp = number;
type NullTimestamp = Timestamp | undefined;

interface VersionedObject {
    version: number;
    [key: string]: any;
}

export class Session {
    id: number;
    start_time: Timestamp;
    name?: string;
    metadata: VersionedObject;

    static createFrom(source: any) {
        if ('string' === typeof source) { source = JSON.parse(source); }
        const result = new Session();
        result.id = source['id'];
        result.start_time = source['start_time'] ? source['start_time'] : null;
        result.name = source['name'] ? source['name'] : null;
        result.metadata = source['metadata'];
        return result;
    }

    // [Session:]


    // [end]
}

export class Image {
    id: number;
    hearscope_session_id: number;
    typ: number;
    url?: string;
    thumbnail_url?: string;
    creation_time: NullTimestamp;
    note?: string;
    metadata: VersionedObject;
    can_diagnose: boolean;

    has_diagnosis: boolean; // TODO(austin): update api to return this

    static createFrom(source: any) {
        if ('string' === typeof source) { source = JSON.parse(source); }
        const result = new Image();
        result.id = source['id'];
        result.hearscope_session_id = source['hearscope_session_id'];
        result.typ = source['typ'];
        result.url = source['url'] ? source['url'] : null;
        result.thumbnail_url = source['thumbnail_url'] ? source['thumbnail_url'] : null;
        result.creation_time = source['creation_time'] ? source['creation_time'] : null;
        result.note = source['note'] ? source['note'] : null;
        result.metadata = source['metadata'];
        result.can_diagnose = source['can_diagnose'];
        return result;
    }

    // [Image:]


    // [end]
}

export class SessionWithImages {
    session: Session;
    images: Image[];

    static createFrom(source: any) {
        if ('string' === typeof source) { source = JSON.parse(source); }
        const result = new SessionWithImages();
        result.session = source['session'] ? Session.createFrom(source['session']) : null;
        result.images = source['images'] ? source['images'].map(function (element: any) { return Image.createFrom(element); }) : null;
        return result;
    }

    // [SessionWithImages:]


    // [end]
}



export class EarDiagnosis {
    image_id: number;
    diagnosis: number;

    static createFrom(source: any) {
        if ('string' === typeof source) { source = JSON.parse(source); }
        const result = new EarDiagnosis();
        result.image_id = source['image_id'];
        result.diagnosis = typeof source['diagnosis'] === 'number' ? source['diagnosis'] : null;
        return result;
    }

    // [EarDiagnosis:]


    // [end]
}
export class PostDiagnosisReq {
    researcher_id: number;
    session_id: number;
    left?: EarDiagnosis;
    right?: EarDiagnosis;

    static createFrom(source: any) {
        if ('string' === typeof source) { source = JSON.parse(source); }
        const result = new PostDiagnosisReq();
        result.researcher_id = source['researcher_id'];
        result.session_id = source['session_id'];
        result.left = source['left'] ? EarDiagnosis.createFrom(source['left']) : null;
        result.right = source['right'] ? EarDiagnosis.createFrom(source['right']) : null;
        return result;
    }

    // [PostDiagnosisReq:]
    valid(): boolean {
        if (this.session_id <= 0) {
            return false;
        }
        if (!this.left && !this.right) {
            return false;
        }
        return true;
    }

    // [end]
}

export interface ValidationImagesRsp {
    images: Image[];
    unvalidated_count: number;
}

export interface AddImageValidationReq {
    id: number;
    valid: boolean;
}

export enum MediaType {
    Unspecified = 0,
    VideoLeft = 1,
    VideoRight = 2,
    ImageLeft = 3,
    ImageRight = 4,
    VoiceNote = 5,
    TextNote = 6,
    Thumbnail = 7,
    Video = 8,
    Image = 9,
}

export enum Diagnosis {
    Unspecified = 0,
    Normal = 1,
    WaxObstruction = 2,
    AcuteOtitisMedia = 3,
    OtitisMediaWithEffusion = 4,
    DryChronicCentralPerforation = 5,
    WetChronicCentralPerforation = 6,
    DeepRetractionPockets = 7,
    Cholesteatoma = 8,
    Myringosclerosis = 9,
    GrommetsInTheTM = 10,
    ForeignBodies = 11,
}

export function DiagnosisString(d: Diagnosis): string {
    switch (d) {
        case Diagnosis.Unspecified: return 'Unspecified';
        case Diagnosis.Normal: return 'Normal';
        case Diagnosis.WaxObstruction: return 'Wax Obstruction';
        case Diagnosis.AcuteOtitisMedia: return 'Acute Otitis Media';
        case Diagnosis.OtitisMediaWithEffusion: return 'Otitis Media With Effusion';
        case Diagnosis.DryChronicCentralPerforation: return 'Dry Chronic Central Perforation';
        case Diagnosis.WetChronicCentralPerforation: return 'Wet Chronic Central Perforation';
        case Diagnosis.DeepRetractionPockets: return 'Deep Retraction Pockets';
        case Diagnosis.Cholesteatoma: return 'Cholesteatoma';
        case Diagnosis.Myringosclerosis: return 'Myringosclerosis';
        case Diagnosis.GrommetsInTheTM: return 'Grommets In The TM';
        case Diagnosis.ForeignBodies: return 'Foreign Bodies';
        default: return 'unknown';
    }
}

export interface MemberStats {
    assigned: number;
    sessions_completed: number;
    images_diagnosed: number;
    diagnosis_counts:  DiagnosisCount;
    rank: number;
}

export interface DiagnosisCount {
    [key: string]: number;
}

interface PatientMetadataV1 {
    version: number;
    discharge?: number;
    ear_pain?: number;
    fever?: number;
    hearing_loss?: number;
    patient_gender?: number;
    patient_age?: number;
}

export interface PatientMetadataDisplay {
    discharge: string;
    ear_pain: string;
    fever: string;
    hearing_loss: string;
    gender: string;
    age: string;
}

const DefaultStr = 'N/A';

export function getPatientMetadata(m?: PatientMetadataV1): PatientMetadataDisplay {
    if (!m) {
        return {
            discharge: DefaultStr,
            ear_pain: DefaultStr,
            fever: DefaultStr,
            hearing_loss: DefaultStr,
            gender: DefaultStr,
            age: DefaultStr,
        };
    }
    return {
        discharge: dischargeStr(m.discharge),
        ear_pain: earPainStr( m.ear_pain),
        fever: feverStr(m.fever),
        hearing_loss: hearingLossStr(m.hearing_loss),
        gender: genderStr(m.patient_gender),
        age: m.patient_age ? m.patient_age.toString() : DefaultStr,
    };
}

function earPainStr(v?: number): string {
    switch (v) {
        case 1: return 'None';
        case 2: return 'Left ear only';
        case 3: return 'Right ear only';
        case 4: return 'Both ears';
        default: return DefaultStr;
    }
}

function hearingLossStr(v?: number): string {
    switch (v) {
        case 1: return 'No';
        case 2: return 'Yes';
        case 3: return 'Uncertain';
        default: return DefaultStr;
    }
}

function dischargeStr(v?: number): string {
    switch (v) {
        case 1: return 'None';
        case 2: return 'Left ear only';
        case 3: return 'Right ear only';
        case 4: return 'Both ears';
        default: return DefaultStr;
    }
}

function genderStr(v?: number): string {
    switch (v) {
        case 1: return 'Unspecified';
        case 2: return 'Male';
        case 3: return 'Female';
        default: return DefaultStr;
    }
}

function feverStr(v?: number): string {
    switch (v) {
        case 1: return 'No';
        case 2: return 'Yes';
        default: return DefaultStr;
    }
}

export class ImgDiagnosis {
    image_id?: number;
    diagnosis?: number;

    constructor(readonly required: boolean, readonly expected_image_id?: number) { }

    setDiagnosis(image_id: number, dx: Diagnosis) {
        if (!this.required) {
            throw new Error('this ear does not require diagnosis');
        }
        if (this.expected_image_id && this.expected_image_id !== image_id) {
            throw new Error('wrong image diagnosed for ear');
        }
        this.image_id = image_id;
        this.diagnosis = dx;
    }

    completed(): boolean {
        if (!this.required) {
            return true;
        }

        return (this.image_id !== undefined) && (this.diagnosis !== undefined);
    }

    getDiagnosis(): EarDiagnosis | null {
        if (!this.required) { return null; }

        return { image_id: this.image_id, diagnosis: this.diagnosis };
    }

    reset() {
        this.image_id = undefined;
        this.diagnosis = undefined;
    }

    copy(): ImgDiagnosis {
        const v = new ImgDiagnosis(this.required, this.expected_image_id);
        v.image_id = this.image_id;
        v.diagnosis = this.diagnosis;
        return v;
    }
}

export function isDiagnosisCompleted(img: Image, left_dx: ImgDiagnosis, right_dx: ImgDiagnosis): boolean {
    if (img.typ === MediaType.ImageLeft) {
        return img.id === left_dx.image_id && left_dx.completed();
    }
    if (img.typ === MediaType.ImageRight) {
        return img.id === right_dx.image_id && right_dx.completed();
    }
    return false;
}

export function getEarBadge(img: Image): string {
    if (img.typ === MediaType.ImageLeft) {
        return 'left';
    }
    if (img.typ === MediaType.ImageRight) {
        return 'right';
    }
    return 'unknown';
}
