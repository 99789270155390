<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div *ngIf="images.length > 0">
  <p>Click on images to mark them as invalid. Invalid images are shown with a red border. Click
  [Next] to submit this batch of images and get the next batch.</p>
  <mat-grid-list [cols]="ncols" gutterSize="16" rowHeight="250">
    <mat-grid-tile *ngFor="let img of images; let i = index">
      <div class="thumbnail-container2" (click)="toggleValid(i)">
        <span class="thumbnail-container" [class.invalid]="!img.valid">
          <img class="thumbnail" [src]="imgURL(img)">
        </span>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <button mat-flat-button color="primary" (click)="onSubmit()">Next</button>
  <span style="margin-left:1em">{{ unvalidated_count - images.length }} images remaining</span>
</div>

<div *ngIf="images.length == 0 && !loading">
  <p>No images to validate.</p>
</div>
