import { Component, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { AuthapiService } from '../../../services/authapi.service';
import { Router } from '@angular/router';
import { MemberStats } from '../../../models/diagnosis';
import { DiagnosisService } from '../../../services/diagnosis.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnDestroy {
  isHandset = false;
  breakpointSub: Subscription;
  userSub: Subscription;
  stats: MemberStats | undefined;

  user: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authsvc: AuthapiService,
    private dxsvc: DiagnosisService,
    private router: Router,
  ) {
    this.breakpointSub = this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
    ]).subscribe(s => {
      this.isHandset = s.matches;
    });

    this.user = authsvc.user;
    this.userSub = authsvc.user$.subscribe(user => {
      this.user = user;
      console.log('nav user', this.user);
      this.refreshStats();
    });

    this.refreshStats();
  }

  ngOnDestroy() {
    this.breakpointSub.unsubscribe();
    this.userSub.unsubscribe();
  }

  logout() {
    this.authsvc.logout();
    this.router.navigate(['/login']);
  }

  refreshStats() {
    if (this.user) {
      console.log('logged in, get rank');
      this.dxsvc.GetMemberStats().subscribe(r => {
        console.log('got rank', r);
        this.stats = r;
      });
    } else {
      this.stats = undefined;
    }
  }
}
