import { Component, Input, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatListOption } from '@angular/material';
import { FilterMenuService } from '../../../services/filter-menu.service';
import { FilterMenuRemote } from './filter-menu-remote';
import { GalleryService } from '../../../services/gallery.service';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss']
})
export class FilterMenuComponent implements OnInit {

  @Input() listItems: { display_name: string; path: string; }[];
  @Input() allSessionTags: string[] = ['ai', 'audio', 'video', 'image'];
  @Input() allPatientTags: string[] = ['this', 'that', 'something', 'somthing else', 'this2', 'that2', 'something2', 'somthing else2'];
  @ViewChild('filterlist', { static: false }) filterList: ElementRef;

  menuToShow: string;
  allTags: string[];
  selectedTags: string[] = [];

  constructor(
    private filterMenuService: FilterMenuService,
    private gallerySerice: GalleryService
  ) {
  }

  ngOnInit() {
    // will be provided as input to element
    this.listItems = [
      { display_name: 'Sessions', path: '#' },
      { display_name: 'Patients', path: '#' },
      { display_name: 'Something', path: '#' },
    ];
    this.allTags = [...this.allPatientTags, ...this.allSessionTags];
    this.menuToShow = this.listItems[0].display_name;
  }

  onNgModelChange(selection: string[]) {
    this.selectedTags = selection;
  }

  getDataTag(e) {
    this.menuToShow = e.display_name;
  }

  applyFilter() {
    this.gallerySerice.filterSessionsByTag(this.selectedTags);
    this.filterMenuService.close();
  }

  cancelFilter() {
    this.filterMenuService.close();
  }


}
