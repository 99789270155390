<div #tableContainer screenSize (windowSizeChanged)="listenToResize()" class="table-container">

  <!-- <mat-toolbar>
    <button mat-button [matMenuTriggerFor]="filter">
      <mat-icon>filter_list</mat-icon> Filter</button>
    <mat-menu #filter="matMenu">
      <button (click)="addFilterTag('true')" mat-menu-item>Valid</button>
      <button (click)="addFilterTag('false')" mat-menu-item>Invalid</button>
      <button (click)="addFilterTag('Overdue')" mat-menu-item>Overdue</button>
    </mat-menu>

    <mat-chip-list>
      <mat-chip *ngFor="let chip of chips; let i = index" color="primary" [removable]="true" (removed)="remove(i)">
        {{ chip === 'true' ? 'Valid' : chip === 'false' ? 'Invalid' : chip }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>

    <span style="flex: 1 1 auto;"></span>

    <div class="filter-header">
      <mat-form-field>
        <input matInput [(ngModel)]="searchTerm" (keyup)="applyFilter($event.target.value)">
        <button mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-placeholder>
          <mat-icon>search</mat-icon> Find
        </mat-placeholder>
      </mat-form-field>
    </div>
  </mat-toolbar> -->

  <mat-toolbar>
    <button cdk-overlay-origin style="margin: 0 10px;"
     mat-button (click)="openFilterPanel()" >
      <mat-icon>filter_list</mat-icon> Filter
    </button>  
    <span style="flex: 1 1 auto;"></span>  
  </mat-toolbar>

  <mat-table #table [dataSource]="dataSource" matSort>

    <!-- model column -->
    <!-- <ng-container matColumnDef="quick_view">
      <mat-header-cell mat-sort-header *matHeaderCellDef></mat-header-cell>
      <mat-cell title="Quick View" *matCellDef="let headset">
        <mat-icon (click)="isExpanded = !isExpanded" >{{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_right'}}</mat-icon>
      </mat-cell>
    </ng-container> -->

    <ng-container matColumnDef="model">
      <mat-header-cell mat-sort-header *matHeaderCellDef>Model</mat-header-cell>
      <mat-cell class="truncate" #cell *matCellDef="let headset">
        {{ headset.model }}
      </mat-cell>
    </ng-container>

    <!-- serial_no column -->
    <ng-container matColumnDef="serial_no">
      <mat-header-cell mat-sort-header *matHeaderCellDef>Serial No.</mat-header-cell>
      <mat-cell class="truncate" *matCellDef="let headset"> {{headset.serial_no}} </mat-cell>
    </ng-container>

    <!-- status column -->
    <ng-container matColumnDef="status">
      <mat-header-cell mat-sort-header *matHeaderCellDef>Calibration</mat-header-cell>
      <mat-cell class="truncate" *matCellDef="let headset"> {{headset.status}} </mat-cell>
    </ng-container>

    <!-- status_validity column -->
    <ng-container matColumnDef="status_validity">
      <mat-header-cell mat-sort-header *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell class="truncate" *matCellDef="let headset"> {{headset.status_validity ? 'Valid' : 'Invalid' }} </mat-cell>
    </ng-container>

    <!-- open_component column -->
    <ng-container matColumnDef="open_component">
      <mat-header-cell mat-sort-header *matHeaderCellDef>View</mat-header-cell>
      <mat-cell class="truncate" title="Open" (click)="openOverlay()" *matCellDef="let headset">
        <mat-icon>visibility</mat-icon>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row #matRow *matRowDef="let row; columns: displayedColumns;" 
      class="element-row pointer" 
      [cdkDetailRow]="row" 
      [cdkDetailRowTpl]="expandedRowTemplate"
      (click)="isExpanded = !isExpanded"
    >
    </mat-row>

    <ng-template   #expandedRowTemplate let-row>
      <div @detailExpand class="expanded-row" style="overflow: hidden;">
        <h1>SOME CONTENT</h1>
        <!-- MOBILE table -->
        <table *ngIf="hiddenColumns.length" @detailExpand style="table-layout: fixed;">
          <tr class="hidden-row row">
            <td *ngFor="let c of hiddenColumns" class="col-heading">{{ c }}</td>
          </tr>
          <tr class="hidden-row row">
            <td *ngFor="let c of hiddenColumns">{{ row[c] }}</td>
          </tr>
        </table>
      </div>
    </ng-template>

  </mat-table>

  <mat-paginator [length]="dataSource" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>



</div>
