import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { LEFT_ARROW, RIGHT_ARROW } from '@angular/cdk/keycodes';
import {
  SessionWithImages,
  Image,
  MediaType,
  Diagnosis,
  PatientMetadataDisplay,
  getPatientMetadata,
  ImgDiagnosis,
  isDiagnosisCompleted,
  getEarBadge,
  DiagnosisString,
} from '../../models/diagnosis';
import {
  ConfirmDialogComponent,
  ConfirmDialogConfig
} from '../../shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.scss']
})
export class SessionDetailsComponent implements OnInit {
  data: DialogData;
  index = 0;
  showDxPanel = false;
  dxform = new FormGroup({
    dx: new FormControl(''),
  });
  allowKeyboard = true;

  patientMetadata: PatientMetadataDisplay;

  @ViewChild('dxbtn', { read: ElementRef, static: false }) dxBtnEl: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<SessionDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: DialogData,
    private confirmDialog: MatDialog,
  ) {
    this.data = {
      leftDx: inputData.leftDx.copy(),
      rightDx: inputData.rightDx.copy(),
      session_data: inputData.session_data,
      index: 0,
    };
    this.index = inputData.index;
  }

  ngOnInit() {
    this.patientMetadata = getPatientMetadata(this.data.session_data.session.metadata);
    this.dxform.valueChanges.subscribe((v) => {
      console.log('form change', v);
      if (!v.dx) { return; }
      this.setDiagnosis(this.data.session_data.images[this.index], parseInt(v.dx, 10));
      if (this.showDxPanel) {
        this.resetDxPanel();
      }
    });

    this.resetDxPanel();
  }

  setDiagnosis(img: Image, dx: Diagnosis) {
    if (img.typ === MediaType.ImageLeft) {
      this.data.leftDx.setDiagnosis(img.id, dx);
    } else if (img.typ === MediaType.ImageRight) {
      this.data.rightDx.setDiagnosis(img.id, dx);
    } else {
      throw new Error('media is not an image');
    }
  }

  closeDialog() {
    this.dialogRef.close(this.data);
  }

  prevImage() {
    this.index--;
    if (this.index < 0) {
      this.index = this.data.session_data.images.length - 1;
    }
    this.resetDxPanel();
  }

  nextImage() {
    this.index = (this.index + 1) % this.data.session_data.images.length;
    this.resetDxPanel();
  }

  openDiagnosisPanel() {
    // check if already open
    if (this.showDxPanel) {
      return;
    }

    const img = this.currentImage();

    let to_confirm = false;
    if (img.typ === MediaType.ImageLeft
      && this.data.leftDx.image_id
      && this.data.leftDx.image_id !== img.id
    ) {
      to_confirm = true;
    } else if (img.typ === MediaType.ImageRight
      && this.data.rightDx.image_id
      && this.data.rightDx.image_id !== img.id
    ) {
      to_confirm = true;
    }

    if (!to_confirm) {
      this.showDiagnosisPanel();
      return;
    }

    const cfg: ConfirmDialogConfig = {
      header: 'Select this image for diagnosis?',
      body: 'Another image has already been selected for diagnosis for this ear. '
       + 'Do you want to remove that diagnosis and use this image instead?',
      ok_string: 'Yes',
      cancel_string: 'No',
    };
    const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, { data: cfg });
    this.allowKeyboard = false;
    dialogRef.afterClosed().subscribe(ok => {
      this.allowKeyboard = true;
      if (!ok) { return; }
      this.showDiagnosisPanel();
    });
  }

  showDiagnosisPanel() {
    const img = this.currentImage();
    const dx = this.diagnosisObj(img);

    this.dxform.enable();
    if (img.id === dx.image_id) {
      this.dxform.setValue({ dx: dx.diagnosis.toString() });
    }
    this.showDxPanel = true;
  }

  currentImage(): Image {
    return this.data.session_data.images[this.index];
  }

  dxButtonText(): string {
    const img = this.currentImage();
    const dx = this.diagnosisObj(img);

    if (!img.can_diagnose) {
      return 'Add Diagnosis';
    }
    if (img.id === dx.image_id && dx.completed()) {
      return `Change Diagnosis`;
    }
    if (img.has_diagnosis) {
      return 'Add Diagnosis';
    } else {
      return 'Select this image as best quality image for diagnosis';
    }
  }

  dxText(): string {
    const img = this.currentImage();
    const dx = this.diagnosisObj(img);

    if (!img.can_diagnose) {
      if (dx.required) {
        return 'Another image must be diagnosed for this ear';
      }
      return 'This ear does not require a diagnosis';
    }
    if (img.id === dx.image_id && dx.completed()) {
      return `Diagnosis: ${DiagnosisString(dx.diagnosis)}`;
    }
    if (dx.completed()) {
      return 'Another image has been diagnosed for this ear';
    }
    return 'Not diagnosed';
  }

  resetDxPanel() {
    this.showDxPanel = false;
    this.dxform.reset();
    this.dxform.disable();
    setTimeout(() => {
      this.dxBtnEl.nativeElement.focus();
    }, 0);

    const img = this.currentImage();
    const dx = this.diagnosisObj(img);
    if (
      img.id === dx.expected_image_id && !dx.completed()
    ) {
      this.showDiagnosisPanel();
    }
  }

  imgURL(img: Image): string {
    if (img.url) {
      return img.url;
    }
    return '/assets/baseline-remove_circle-24px.svg'; // TODO(austin): replace with another image
  }

  getEarBadge(img: Image): string {
    return getEarBadge(img);
  }

  imgDxCompleted(img: Image): boolean {
    return isDiagnosisCompleted(img, this.data.leftDx, this.data.rightDx);
  }

  imgError(ev: Event, idx: number) {
    console.log(ev, idx);
    this.data.session_data.images[idx].url = null;
    const img = <HTMLImageElement>ev.target;
    img.src = '/assets/baseline-remove_circle-24px.svg';
    img.width = 300;
    img.height = 300;
  }

  @HostListener('window:keyup', ['$event'])
  keyHandler(ev: KeyboardEvent) {
    if (!this.allowKeyboard) {
      console.log('keyboard blocked');
      return;
    }
    if (ev.keyCode === LEFT_ARROW) {
      this.prevImage();
      return;
    }
    if (ev.keyCode === RIGHT_ARROW) {
      this.nextImage();
      return;
    }
  }

  // diagnosisObj returns the corresponding left or right diagnosis depending on the image ear
  diagnosisObj(img: Image): (ImgDiagnosis | undefined) {
    if (img.typ === MediaType.ImageLeft) {
      return this.data.leftDx;
    }

    if (img.typ === MediaType.ImageRight) {
      return this.data.rightDx;
    }

    return undefined;
  }
}

interface DialogData {
  session_data: SessionWithImages;
  leftDx: ImgDiagnosis;
  rightDx: ImgDiagnosis;
  index: number;
}
