import { Injectable } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { filter, tap, map, toArray } from 'rxjs/operators';
export interface SessionData {
  id: number;
  url: string;
  patient_no: string;
  tags?: string[];
}
@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  sessions: SessionData[] = [
    { id: 1, url: '/assets/hearscope.png', patient_no: '001', tags: ['ai', 'image']},
    { id: 2, url: '/assets/hearscope.png', patient_no: '002', tags: ['ai', 'image'] },
    { id: 3, url: '/assets/hearscope.png', patient_no: '003', tags: ['audio'] },
    { id: 4, url: '/assets/hearscope.png', patient_no: '004', tags: ['image'] },
    { id: 5, url: '/assets/hearscope.png', patient_no: '005', tags: ['image'] },
    { id: 6, url: '/assets/hearscope.png', patient_no: '006', tags: ['image'] },
    { id: 7, url: '/assets/hearscope.png', patient_no: '007', tags: ['image'] },
    { id: 8, url: '/assets/hearscope.png', patient_no: '008', tags: ['image'] },
    { id: 9, url: '/assets/hearscope.png', patient_no: '009', tags: ['audio', 'image', 'ai'] },
    { id: 10, url: '/assets/hearscope.png', patient_no: '010', tags: ['image'] },
    { id: 11, url: '/assets/hearscope.png', patient_no: '011', tags: ['image'] },
    { id: 12, url: '/assets/hearscope.png', patient_no: '012', tags: ['image'] },
    { id: 13, url: '/assets/hearscope.png', patient_no: '013', tags: ['image'] },
    { id: 14, url: '/assets/hearscope.png', patient_no: '014', tags: ['ai', 'audio', 'image'] },
    { id: 15, url: '/assets/hearscope.png', patient_no: '015', tags: ['image'] },
    { id: 16, url: '/assets/hearscope.png', patient_no: '016', tags: ['image'] },
    { id: 17, url: '/assets/hearscope.png', patient_no: '002', tags: ['image'] },
    { id: 18, url: '/assets/hearscope.png', patient_no: '003', tags: ['image', 'ai'] },
    { id: 19, url: '/assets/hearscope.png', patient_no: '004', tags: ['image'] },
    { id: 20, url: '/assets/hearscope.png', patient_no: '005', tags: ['image'] },
    { id: 21, url: '/assets/hearscope.png', patient_no: '006', tags: ['image', 'ai'] },
    { id: 22, url: '/assets/hearscope.png', patient_no: '007', tags: ['image'] },
    { id: 23, url: '/assets/hearscope.png', patient_no: '008', tags: ['ai', 'image'] },
    { id: 24, url: '/assets/hearscope.png', patient_no: '009', tags: ['image'] },
    { id: 25, url: '/assets/hearscope.png', patient_no: '010', tags: ['image'] },
    { id: 26, url: '/assets/hearscope.png', patient_no: '011', tags: ['audio', 'image'] },
    { id: 27, url: '/assets/hearscope.png', patient_no: '012', tags: ['image'] },
    { id: 30, url: '/assets/hearscope.png', patient_no: '013', tags: ['image', 'ai'] },
    { id: 31, url: '/assets/hearscope.png', patient_no: '014', tags: ['image'] },
    { id: 32, url: '/assets/hearscope.png', patient_no: '015', tags: ['image'] },
    { id: 33, url: '/assets/hearscope.png', patient_no: '016', tags: ['ai', 'image'] },
  ];

  constructor() {}

  getSessionData(id?: number) {
    if (id) {
      return observableOf<SessionData[]>(
        this.sessions.filter(s => s.id === id)
      );
    } else {
      return observableOf<SessionData[]>(this.sessions);
    }
  }

  filterSessionsByTag(tags: string[]) {
    console.log(tags);
    const filteredSessions = this.getSessionData();

    // filteredSessions.

  }
}
