import { Injectable, Inject, ComponentFactoryResolver, Injector, ApplicationRef, ComponentRef } from '@angular/core';
import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { LightboxViewComponent } from '../shared/components/lightbox-view/lightbox-view.component';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LightboxService {

  // attach component to portal
  private lightboxContainerPortal: ComponentPortal<LightboxViewComponent>;
  // create ref to portal host for attaching to DOM element
  private bodyPortalHost: DomPortalHost;

  constructor(
    // inject document ref and deps needed by DOM portal host
    @Inject(DOCUMENT) private document: any,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {
    // create portal
    this.lightboxContainerPortal = new ComponentPortal(LightboxViewComponent);
    // create anchor for portal host to body tag
    this.bodyPortalHost = new DomPortalHost(document.body, this.componentFactoryResolver, this.appRef, this.injector);
  }

  show(id: number) {
    // attach portal to host
    const componentRef: ComponentRef<LightboxViewComponent> = this.bodyPortalHost.attach(this.lightboxContainerPortal);
    componentRef.instance.imageId = id;
    // subscribe to close event
    componentRef.instance.close.subscribe(() => this.hide());
  }

  hide() {
    // detach portal form host
    this.bodyPortalHost.detach();
  }
}
