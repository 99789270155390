import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LightboxService } from '../../services/lightbox.service';
import { GalleryService } from '../../services/gallery.service';
import { LightboxViewComponent } from '../../shared/components/lightbox-view/lightbox-view.component';
import { AngularMaterialModule } from '../../shared/angular-material.module';
import { GalleryViewComponent } from './gallery-view.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { GalleryItemComponent } from './gallery-item/gallery-item.component';

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [LightboxViewComponent, GalleryViewComponent, GalleryItemComponent],
  providers: [
    LightboxService,
    GalleryService,
  ],
  entryComponents: [LightboxViewComponent]
})
export class GalleryModule { }
