<div class="wrapper">

  <span #imageWrapper class="image-wrapper" *ngFor="let data of sessionData">
    <span class="close-icon" (click)="close.next()">
      <mat-icon>close</mat-icon>
    </span>
    <mat-card (blur)="close.next()">
      <img (click)="expandTestDetail()" mat-card-image [src]="data.url" alt="image - {{ data.id }}">
      <mat-card-actions>
        <button (click)="expandTestDetail()" mat-button>View Test</button>
      </mat-card-actions>
    </mat-card>
  </span>

  <span class="expand-detail" #expandDetail>
    <mat-accordion>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Test overview
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list role="list">
          <mat-list-item role="listitem">Conducted on 13/05/2017</mat-list-item>
          <mat-list-item role="listitem">Patient Id: </mat-list-item>
          <mat-list-item role="listitem">Result: Pending</mat-list-item>
        </mat-list>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Tag this image
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field>
          <input matInput placeholder="Enter new tag">
        </mat-form-field>
      </mat-expansion-panel>

    </mat-accordion>
  </span>
</div>
