import { TemplateRef, ViewContainerRef } from '@angular/core';
var CdkDetailRowDirective = /** @class */ (function () {
    function CdkDetailRowDirective(vcRef) {
        this.vcRef = vcRef;
    }
    Object.defineProperty(CdkDetailRowDirective.prototype, "expended", {
        get: function () {
            return this.opened;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CdkDetailRowDirective.prototype, "cdkDetailRow", {
        set: function (value) {
            if (value !== this.row) {
                this.row = value;
                // this.render();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CdkDetailRowDirective.prototype, "template", {
        set: function (value) {
            if (value !== this.tRef) {
                this.tRef = value;
                // this.render();
            }
        },
        enumerable: true,
        configurable: true
    });
    CdkDetailRowDirective.prototype.onClick = function () {
        this.toggle();
    };
    CdkDetailRowDirective.prototype.toggle = function () {
        if (this.opened) {
            this.vcRef.clear();
        }
        else {
            this.render();
        }
        this.opened = this.vcRef.length > 0;
    };
    CdkDetailRowDirective.prototype.render = function () {
        this.vcRef.clear();
        if (this.tRef && this.row) {
            this.vcRef.createEmbeddedView(this.tRef, { $implicit: this.row });
        }
    };
    return CdkDetailRowDirective;
}());
export { CdkDetailRowDirective };
