import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {A11yModule} from '@angular/cdk/a11y';
import {BidiModule} from '@angular/cdk/bidi';
import {ObserversModule} from '@angular/cdk/observers';
import {OverlayModule} from '@angular/cdk/overlay';
import {PlatformModule} from '@angular/cdk/platform';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';

import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar-old/sidebar.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { CalendarPickerComponent } from './components/date-picker/calendar-picker/calendar-picker.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { CdkDetailRowDirective } from './directives/cdk-detail-row.directive';
import { ScreenSizeDirective } from './directives/screen-size.directive';
import { AngularMaterialModule } from './angular-material.module';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ChipFilterComponent } from './components/chip-filter/chip-filter.component';
import { SearchFilterPipe } from './filter.pipe';
import { FilterMenuComponent } from './components/filter-menu/filter-menu.component';
import { FilterMenuService } from '../services/filter-menu.service';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    CdkTableModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    ToolbarComponent,
    CalendarPickerComponent,
    DatePickerComponent,
    CdkDetailRowDirective,
    ScreenSizeDirective,
    NavigationComponent,
    ChipFilterComponent,
    SearchFilterPipe,
    FilterMenuComponent,
    ConfirmDialogComponent,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    HeaderComponent,
    SidebarComponent,
    ToolbarComponent,
    CalendarPickerComponent,
    DatePickerComponent,
    NavigationComponent,
    // CDK
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    ScrollDispatchModule,
    CdkStepperModule,
    CdkTableModule,
    CdkDetailRowDirective,
    ScreenSizeDirective,
    AngularMaterialModule,
    ChipFilterComponent
  ],
  providers: [
    FilterMenuService
  ],
  entryComponents: [ConfirmDialogComponent],
})
export class SharedModule { }
