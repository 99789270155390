import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SessionWithImages, PostDiagnosisReq, AddImageValidationReq, MemberStats, DiagnosisCount, ValidationImagesRsp } from '../models/diagnosis';
import { AuthapiService } from './authapi.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./authapi.service";
var DiagnosisService = /** @class */ (function () {
    function DiagnosisService(http, authsvc) {
        this.http = http;
        this.authsvc = authsvc;
        this.endpoint = environment.diagnosisEndpoint;
    }
    DiagnosisService.prototype.GetSessions = function () {
        console.log('GetSessions');
        // TEMP(austin): use rearcher_id 1 for now, API should infer it from authentication token
        var res = this.http.post(this.endpoint + '/v1/get_new_sessions', null, { headers: this.authHeaders() }).pipe(unwrap2);
        return res;
    };
    DiagnosisService.prototype.PostDiagnosis = function (req) {
        return this.http.post(this.endpoint + '/v1/diagnosis', req, { headers: this.authHeaders() });
    };
    DiagnosisService.prototype.SkipSessions = function (req) {
        return this.http.post(this.endpoint + "/v1/sessions_skip", req, { headers: this.authHeaders() }).pipe(unwrap2);
    };
    DiagnosisService.prototype.GetImagesToValidate = function () {
        return this.http.get(this.endpoint + "/v1/vimgs").pipe(unwrap2);
    };
    DiagnosisService.prototype.AddImageValidation = function (req) {
        return this.http.patch(this.endpoint + "/v1/image/validate", req).pipe(unwrap2);
    };
    DiagnosisService.prototype.GetMemberStats = function () {
        return this.http.get(this.endpoint + "/v1/member_stats", { headers: this.authHeaders() }).pipe(unwrap2);
    };
    DiagnosisService.prototype.GetGlobalStats = function () {
        return this.http.get(this.endpoint + "/v1/global_stats", { headers: this.authHeaders() }).pipe(unwrap2);
    };
    DiagnosisService.prototype.authHeaders = function () {
        return new HttpHeaders({
            Authorization: 'token ' + this.authsvc.token,
        });
    };
    DiagnosisService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DiagnosisService_Factory() { return new DiagnosisService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthapiService)); }, token: DiagnosisService, providedIn: "root" });
    return DiagnosisService;
}());
export { DiagnosisService };
function unwrap(rsp) {
    return rsp.data;
}
function unwrap2(source) {
    return new Observable(function (observer) {
        return source.subscribe({
            next: function (x) { observer.next(x.data); },
            error: function (err) { observer.error(err); },
            complete: function () { observer.complete(); },
        });
    });
}
var APIResult = /** @class */ (function () {
    function APIResult() {
    }
    return APIResult;
}());
export { APIResult };
