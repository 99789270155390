/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gallery-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./gallery-item/gallery-item.component.ngfactory";
import * as i3 from "./gallery-item/gallery-item.component";
import * as i4 from "../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i5 from "@angular/material/toolbar";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "@angular/cdk/overlay";
import * as i13 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i14 from "@angular/material/icon";
import * as i15 from "./gallery-view.component";
import * as i16 from "../../services/lightbox.service";
import * as i17 from "../../services/filter-menu.service";
import * as i18 from "../../services/gallery.service";
var styles_GalleryViewComponent = [i0.styles];
var RenderType_GalleryViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GalleryViewComponent, data: {} });
export { RenderType_GalleryViewComponent as RenderType_GalleryViewComponent };
function View_GalleryViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "gallery-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-gallery-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLightboxOverlay(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_GalleryItemComponent_0, i2.RenderType_GalleryItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.GalleryItemComponent, [], { currentItem: [0, "currentItem"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GalleryViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { paginator: 0 }), i1.ɵqud(671088640, 2, { overlayOrigin: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 10, "mat-toolbar", [["class", "mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i4.View_MatToolbar_0, i4.RenderType_MatToolbar)), i1.ɵdid(3, 4243456, null, 1, i5.MatToolbar, [i1.ElementRef, i6.Platform, i7.DOCUMENT], null, null), i1.ɵqud(603979776, 3, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(5, 0, null, 0, 6, "button", [["cdk-overlay-origin", ""], ["mat-button", ""], ["style", "margin: 0 10px;"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFilterPanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(7, 16384, [[2, 4]], 0, i12.CdkOverlayOrigin, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i13.View_MatIcon_0, i13.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i14.MatIcon, [i1.ElementRef, i14.MatIconRegistry, [8, null], [2, i14.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["filter_list"])), (_l()(), i1.ɵted(-1, 0, [" Filter "])), (_l()(), i1.ɵeld(12, 0, null, 0, 0, "span", [["style", "flex: 1 1 auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "gallery-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryViewComponent_1)), i1.ɵdid(15, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 9, 0); var currVal_6 = _co.sessions; _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 3)._toolbarRows.length === 0); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 6).disabled || null); var currVal_3 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 9).inline; var currVal_5 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_4, currVal_5); }); }
export function View_GalleryViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gallery-view", [], null, null, null, View_GalleryViewComponent_0, RenderType_GalleryViewComponent)), i1.ɵdid(1, 114688, null, 0, i15.GalleryViewComponent, [i16.LightboxService, i17.FilterMenuService, i18.GalleryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GalleryViewComponentNgFactory = i1.ɵccf("app-gallery-view", i15.GalleryViewComponent, View_GalleryViewComponent_Host_0, {}, {}, []);
export { GalleryViewComponentNgFactory as GalleryViewComponentNgFactory };
