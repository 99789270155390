import { Injectable } from '@angular/core';

@Injectable()
export class HardwareService {
  
  data: Headset[];
  constructor() { }

  getHeadsets(): Headset[] {
    return this.fakeData;
  }
    private fakeData = [
      { model: "Sennheiser_HD_202",	serial_no: "sMaPFK", status: "Overdue by 485 days" , device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "1f48a66b70", status: "Due in 220 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "8f8f206842", status: "Overdue by 333 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "ba312b389a", status: "Overdue by 232 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "6 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "55c29fbc09", status: "Due in 99 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "6 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "a20076137a", status: "Due in 126 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "6 months", status_validity: "0", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "development", status: "Overdue by 124 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "6 months", status_validity: "0", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "493c21df8e", status: "Due in 123 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "0", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "demodemo", status: "Overdue by 202 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "0", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_202",	serial_no: "sMaPFK", status: "Overdue by 485 days" , device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "0", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "1f48a66b70", status: "Due in 220 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "8f8f206842", status: "Overdue by 333 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "ba312b389a", status: "Overdue by 232 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "55c29fbc09", status: "Due in 99 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "01/02/2016", calibration_due_date: "4 months", status_validity: "0", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "a20076017a", status: "Due in 129 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "01/02/2016", calibration_due_date: "4 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "development", status: "Overdue by 124 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "01/02/2016", calibration_due_date: "4 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "493c21df8e", status: "Due in 123 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "01/02/2016", calibration_due_date: "4 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "demodemo", status: "Overdue by 202 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2013", calibration_due_date: "11 months", status_validity: "0", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_202",	serial_no: "sMaPFK", status: "Overdue by 485 days" , device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "1f48a66b70", status: "Due in 20 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "8f8f206842", status: "Overdue by 333 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "ba312b389a", status: "Overdue by 232 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2015", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "55c29fbc09", status: "Due in 99 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "a20076137a", status: "Due in 129 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2016", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "development", status: "Overdue by 124 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2014", calibration_due_date: "11 months", status_validity: "0", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "493c21df8e", status: "Due in 123 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2014", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_280",	serial_no: "demodemo", status: "Overdue by 202 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2014", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
      { model: "Sennheiser_HD_202",	serial_no: "aed1648ee0", status: "Due in 129 days", device_model_no: "GT-I9505"
      , technician_name: "Mathieu", calibration_facility: "University of Pretoria", hearscreen_version: "1.0", calibration_date: "13/02/2014", calibration_due_date: "11 months", status_validity: "1", certificate_url: '/Calibration_Certificate.pdf'},
    ]
  

}

export class HeadsetSearchCriteria {
  sortColumn: string;
  sortDirection: string;
}

export class Headset {
  model: string;
  serial_no: string;
  status: string;
  device_model_no: string;
  technician_name: string;
  calibration_facility: string;
  hearscreen_version: string;
  calibration_date: string;
  calibration_due_date: string;
  status_validity: string;
  certificate_url: string;
}
