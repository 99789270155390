// modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticatedModule } from './authenticated/authenticated.module';

// components
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule } from '@angular/material';
import { DiagnosisService } from './services/diagnosis.service';
import { AuthapiService } from './services/authapi.service';

// services

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthenticatedModule,
    LayoutModule,
  ],
  providers: [DiagnosisService, AuthapiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
